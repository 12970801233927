<template>
  <div id="main-page">
    <div class="container">
      <div class="row">
        <app-bar
          title="Pembayaran"
          previous-link="/wallet/top-up"/>
      </div>
      <div class="row">
        <div class="col-12 px-3">
          <div>
            <div class="payment-method mb-3">
              <div class="payment-method-header mb-3">
                <div class="payment-method-title fw-700 fs-14 color-neutral-900">Metode Pembayaran</div>
              </div>
              <div class="payment-method-body d-flex flex-column gap-1">
                <div
                  class="payment-method-item d-flex align-items-center justify-content-between">
                  <div
                    class="payment-method-item-group d-flex align-items-center gap-2">
                    <div class="payment-method-item-group-image">
                      <img src="@/assets/icon/payment/ovo.svg" alt="Icon">
                    </div>
                    <div class="">
                      <div class="payment-method-item-group-title fw-700 fs-14 color-neutral-900 mb-1">OVO Payment</div>
                      <p class="payment-method-item-group-expired fw-400 fs-12 color-neutral-600 mb-0">Bayar Menggunakan OVO</p>
                    </div>
                  </div>
                  <input
                    class="input-payment-method"
                    type="radio"
                    name="input-payment-method"
                    @click="selectedPaymentMethod = 1">
                </div>
                <div
                  class="payment-method-item d-flex align-items-center justify-content-between">
                  <div class="payment-method-item-group d-flex align-items-center gap-2">
                    <div class="payment-method-item-group-image">
                      <img src="@/assets/icon/payment/gopay.svg" alt="Icon">
                    </div>
                    <div class="">
                      <div class="payment-method-item-group-title fw-700 fs-14 color-neutral-900 mb-1">Gopay</div>
                      <p class="payment-method-item-group-expired fw-400 fs-12 color-neutral-600 mb-0">Bayar Menggunakan Gopay</p>
                    </div>
                  </div>
                  <input
                    class="input-payment-method"
                    type="radio"
                    name="input-payment-method"
                    @click="selectedPaymentMethod = 2">
                </div>
              </div>
            </div>
            <div class="payment-method mb-3">
              <div class="payment-method-header mb-1">
                <div class="payment-method-title fw-400 fs-12 color-neutral-700">Bank Transfer</div>
              </div>
              <div class="payment-method-body d-flex flex-column gap-2">
                <div class="payment-method-item d-flex align-items-center justify-content-between">
                  <div class="payment-method-item-group d-flex align-items-center gap-2">
                    <div class="payment-method-item-group-image">
                      <img src="@/assets/icon/payment/bca.svg" alt="Icon">
                    </div>
                    <div class="payment-method-item-group-title fw-700 fs-14 color-neutral-900">BCA Virtual Account</div>
                  </div>
                  <input
                    class="input-payment-method"
                    type="radio"
                    name="input-payment-method"
                    @click="selectedPaymentMethod = 3">
                </div>
                <div class="payment-method-item d-flex align-items-center justify-content-between">
                  <div class="payment-method-item-group d-flex align-items-center gap-2">
                    <div class="payment-method-item-group-image">
                      <img src="@/assets/icon/payment/mandiri.svg" alt="Icon">
                    </div>
                    <div class="payment-method-item-group-title fw-700 fs-14 color-neutral-900">Mandiri Virtual Account</div>
                  </div>
                  <input
                    class="input-payment-method"
                    type="radio"
                    name="input-payment-method"
                    @click="selectedPaymentMethod = 4">
                </div>
                <div class="payment-method-item d-flex align-items-center justify-content-between">
                  <div class="payment-method-item-group d-flex align-items-center gap-2">
                    <div class="payment-method-item-group-image">
                      <img src="@/assets/icon/payment/bni.svg" alt="Icon">
                    </div>
                    <div class="payment-method-item-group-title fw-700 fs-14 color-neutral-900">BNI Virtual Account</div>
                  </div>
                  <input
                    class="input-payment-method"
                    type="radio"
                    name="input-payment-method"
                    @click="selectedPaymentMethod = 5">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-3">
          <div class="bottom-bar p-3 p-md-0 mb-md-3 text-center">
            <button class="btn btn-orange w-100" data-bs-toggle="modal" data-bs-target="#modalStatus">
              <span class="fw-600 fs-14 text-white">Bayar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    id="modalStatus"
    class="modal modal-status fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content border-0">
        <div class="modal-body d-flex flex-column align-items-center p-0">
          <div class="modal-body-image mb-3">
            <img
              :src="success ? require('@/assets/icon/payment-success.svg') : require('@/assets/icon/payment-failed.svg')"
              alt="Icon Status Pembayaran">
          </div>
          <span class="modal-body-title fw-700 fs-18 color-neutral-900 mb-1">
            {{ success ? 'Pembayaran Berhasil' : 'Pembayaran Gagal' }}
          </span>
          <p class="modal-body-desc fw-400 fs-14 color-neutral-600 mb-0 text-center mb-3">
            {{ success ? 'Anda berhasil melakukan pengisian saldo' : 'Gagal mengisi saldo, silahkan masukkan PIN yg benar' }}
          </p>
          <button
            type="button"
            class="btn btn-primary w-100"
            data-bs-dismiss="modal">
            <span class="fw-600 fs-14 lh-20">
              {{ success ? 'Oke Mantap' : 'Tutup' }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import AppBar from '@/components/AppBar'
import { ref } from 'vue'

export default {
  components: {
    AppBar
  },
  setup () {
    const selectedPaymentMethod = ref(null)
    const success = ref(true)

    return {
      selectedPaymentMethod,
      success
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-method {
  &-item {
    padding: 8px 0;
    border-radius: 12px;

    .input-payment-method {
      width: 24px;
      height: 24px;

      &-label:active {
        color: #4A5568;
      }
    }

    .input-payment-method:checked {
      accent-color: #007A98;
    }
  }
}

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: none;
  }
}

.modal-status {
  .modal-dialog {
    @media (max-width: 520px) {
      width: 100%;
      position: fixed;
      top: auto;
      right: auto;
      left: auto;
      bottom: 0;
      margin: 0;
    }

    .modal-content {
      padding: 36px;
      border-radius: 20px !important;

      @media (max-width: 520px) {
        border-radius: 20px 20px 0px 0px !important;
      }

      .modal-body {
        &-image {
          width: 148px;
          height: 148px;
          object-fit: contain;
        }

        &-desc {
          max-width: 255px;
        }
      }
    }
  }
}

.modal.fade .modal-dialog {
  transform: translate3d(0, 100vh, 0);
  transition: transform 0.5s ease-out;
}

.modal.show .modal-dialog {
  transform: translate3d(0, 0, 0);
}
</style>
